// Generated by Framer (b12dec4)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {zmjvnmUUl: {hover: true}};

const cycleOrder = ["zmjvnmUUl"];

const variantClassNames = {zmjvnmUUl: "framer-v-1yllj7z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 504, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zmjvnmUUl", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6vrWV", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 1176, intrinsicWidth: 1176, pixelHeight: 1176, pixelWidth: 1176, sizes: "min(588px, 100vw)", src: new URL("assets/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href} 1024w, ${new URL("assets/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href} 1176w`}} className={cx("framer-1yllj7z", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zmjvnmUUl"} ref={ref} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({"zmjvnmUUl-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 1176, intrinsicWidth: 1176, pixelHeight: 1176, pixelWidth: 1176, sizes: "min(588px, 100vw)", src: new URL("assets/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href} 1024w, ${new URL("assets/OjIRLCN5t2f9GRT9IzDaEmSTec.jpg", import.meta.url).href} 1176w`}} className={"framer-kg270n"} data-framer-name={"thumbnail"} layoutDependency={layoutDependency} layoutId={"Se2ByyNV4"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6vrWV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6vrWV .framer-7yojao { display: block; }", ".framer-6vrWV .framer-1yllj7z { height: 588px; overflow: hidden; position: relative; width: 588px; will-change: var(--framer-will-change-override, transform); }", ".framer-6vrWV .framer-kg270n { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-6vrWV .framer-v-1yllj7z .framer-1yllj7z { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 588
 * @framerIntrinsicWidth 588
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"pTy2DvGCF":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerwxkJnyywM: React.ComponentType<Props> = withCSS(Component, css, "framer-6vrWV") as typeof Component;
export default FramerwxkJnyywM;

FramerwxkJnyywM.displayName = "card-component";

FramerwxkJnyywM.defaultProps = {height: 588, width: 588};

addFonts(FramerwxkJnyywM, [])